@mixin respond($breakpoint) {
    @if $breakpoint==phone-port-fold {
        @media (max-width: 17.5em) {
            @content;
        }
    }

    @if $breakpoint==phone-port-5c {
        @media (max-width: 21em) {
            @content;
        }
    }

    @if $breakpoint==phone-port {
        @media (max-width: 28.13em) {
            @content;
        }
    }

    @if $breakpoint==phone-land {
        @media (max-width: 37.5em) {
            @content;
        }
    }

    @if $breakpoint==phone-land-only-fold {
        @media (max-width: 700px) and (max-height: 17.5em) {
            @content;
        }
    }

    @if $breakpoint==phone-land-only-5c {
        @media (max-width: 37.5em) and (max-height: 40em) {
            @content;
        }
    }

    @if $breakpoint==phone-land-only {
        @media (max-width: 55.62em) and (max-height: 30em) {
            @content;
        }
    }

    @if $breakpoint==700px {
        @media (max-width: 43.75em) {
            @content;
        }
    }

    @if $breakpoint==800px {
        @media (max-width: 50em) {
            @content;
        }
    }

    @if $breakpoint==tab-port {
        @media (max-width: 55.62em) {
            @content;
        }
    }

    @if $breakpoint==min-wid {
        @media (max-width: 65.37em) {
            @content;
        }
    }

    @if $breakpoint==tab-land {
        @media (max-width: 75em) {
            @content;
        }
    }

    @if $breakpoint==standard {
        @media (min-width: 90em) {
            @content;
        }
    }

    // Vertical Point
    @if $breakpoint==big-height {
        @media (min-height: 109em) {
            @content;
        }
    }

    @if $breakpoint==big-desktop {
        @media (min-width: 112.5em) {
            @content;
        }
    }
}
