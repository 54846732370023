@import './variables.scss';
@import './responsive.scss';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 60%; // 1rem = 9.6px, 9.6/16 = 60%

    @include respond(tab-port) {
        font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%
    }

    @include respond(phone-port-5c) {
        font-size: 50%; // 1rem = 8px, 8/16 = 50%
    }
}

body {
    font-family: var(--font-family-lato);
    font-weight: 400;
    color: var(--color-white-text);
    box-sizing: border-box;

    position: relative;

    background-color: var(--color-home-body);
    background-image: url('https://www.propercardgames.com/static/img/back/fabric.png');
    background-repeat: repeat;
    background-blend-mode: darken;
}

a,
a:visited,
.btn-link,
.btn-link:visited {
    position: relative;
    text-decoration: none;
    color: var(--color-white-text);
}

a:hover,
.btn-link:hover,
.header-box__logins-box--item:hover {
    color: var(--color-link-highlight);
    cursor: pointer;
}

// ------------------------------------------
// Scroll Bar Color
// width
::-webkit-scrollbar {
    -webkit-appearance: none; // NEW
    width: 15px;
    -webkit-overflow-scrolling: auto;
}

// Track
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--color-white-tinted-dark);
    border-radius: 20px;
}

// Handle
::-webkit-scrollbar-thumb {
    background: var(--color-white-tinted-dark);
    background: var(--color-button-beige);
    border-radius: 20px;
}

// ------------------------------------------
// Change Autocomplete styles in Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: inherit;
    -webkit-background-origin: var(--color-header-footer-border);
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
}
