:root {
    // COLOR VARIABLES
    --color-header-dark: #252525;
    --color-header-dark-blue: #1c1d1d;
    --color-header-mid-blue: #24282d;
    --color-header-light-blue: #282e36;
    --color-header-lightest-blue: #353a42;

    --color-header-dark-blue-tinted: #1924247a;

    --color-header-footer-border: rgba(245, 245, 245, 0.7);
    --color-textfield: rgba(245, 245, 245, 0.8);
    --color-table-border: rgba(245, 245, 245, 0.7);

    --color-white-text: rgba(245, 245, 245, 0.85); //#e2e3e5;

    --color-disabled-text: #6a6a6a;

    --color-home-body: #455d7f;

    --color-link-highlight: #e0ac3c;
    --color-button-beige: #cfc29b;
    --color-button-beige-tinted: rgba(207, 194, 155, 0.7);

    --color-error-msg: #f8834d;
    --color-notification-msg: #e3d50c;

    --color-footer-menu-item: rgba(245, 245, 245, 0.7);

    --color-orange-tinted: #e0ac3cd2;
    --color-white-tinted: #a8aeac;
    --color-white-tinted-dark: #868686;
    --color-white-tinted-light: #b4bebb;

    --color-background-table: #1515156b;
    --color-background-tinted: #151515c9; // darker
    --color-background-tinted-light: #15151523;

    --color-background-tinted-dark: #151515c9;
    --color-background-tinted-darkest: #151515ea;

    // FONT VARIABLES
    --font-family-prosto: 'Prosto One', Arial, sans-serif;
    --font-family-lato: 'Lato', 'Calibri', Arial, sans-serif;

    // SHADOW VARIABLES
    --shadow-main: 0px 5px 15px 2px rgba(0, 0, 0, 0.2);
}
